import React from "react"
import { graphql } from "gatsby"
// components
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import TopElement from "../../components/topElement"
import TopElementCenter from "../../components/topElementCenter"
import RefElement from "../../components/refElement"
import "../../assets/css/referenzen.css"

// import ReferenzenGallery from '../../components/gallery'


const CraftServicesPage = ({ data }) => {

  return (
    <Layout initialBackgroundColor="bg-white"
      headerTitle='Ausgewählte Arbeiten.'
      headerSub='Handwerk aus Leidenschaft'
      bgImg='handwerkReferenz'>
      <Seo title="Handwerk aus Leidenschaft" />

      <TopElement text='Modern oder klassisch? Alpin oder urban? Immer mit Stil, immer individuell: ausgewählte Arbeiten im alpinen, ländlichen und urbanen Raum der Schweiz.'/>



      <div className="grid max-w-screen-lg grid-cols-6 gap-8 pt-0 mx-auto md:pt-12 2xl:max-w-screen-xl">

          <RefElement projektName='Zürich' refNr="1" caption='Mehrfamilienhaus, Zürich' className="col-span-6 md:col-span-3 lg:col-span-2 h-520" />
          <RefElement projektName='Quellenhof' refNr="2" caption='Quellenhof, Bad Ragaz' className="col-span-6 md:col-span-3 lg:col-span-2 h-520" />
          <RefElement projektName='Esplanade' refNr="3" caption='Esplanade, Davos' className="col-span-6 md:col-span-3 lg:col-span-2 h-520" />

          <RefElement projektName='Valsana' refNr="4" caption='Valsana, Arosa' className="col-span-6 md:col-span-3 h-520" />
          <RefElement projektName='Carlton' refNr="5" caption='Carlton, St. Moritz' className="col-span-6 md:col-span-3 h-520" />
          
      </div>


      <TopElementCenter title="Mehrfamilienhäuser"/>
      
      <div className="grid max-w-screen-lg grid-cols-1 gap-8 pt-0 mx-auto list-none sm:grid-cols-2 md:grid-cols-4 2xl:max-w-screen-xl">
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Sonnhaldenstrasse 12</strong><br /> 8032 Zürich</li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Zollikerstrasse 204</strong><br /> 8008 Zürich </li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Spiegelhofstrasse 56</strong><br /> 8032 Zürich </li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Münsterhof 13</strong><br /> 8001 Zürich </li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Dufoustrasse 62</strong><br /> 8702 Zürich</li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Claridenstrasse 25</strong><br /> 8002 Zürich </li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Dufourstrasse 93</strong><br /> 8008 Zürich</li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Emil-Klöti-Strasse 33-37</strong><br /> 8049 Zürich</li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Scheideggstrasse 81 + 83</strong><br /> 8038 Zürich </li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Bäulistrasse 51 + 53</strong><br /> 8049 Zürich </li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Südstrasse 102/106</strong><br /> 8008 Zürich </li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Bertastrasse 97/99</strong><br /> 8055 Zürich </li>
      </div>


      <TopElementCenter title="Hotels und mehr"/>

      <div className="grid max-w-screen-lg grid-cols-1 gap-8 pt-0 mx-auto list-none sm:grid-cols-2 md:grid-cols-4 2xl:max-w-screen-xl">
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Badrutt’s Palace Hotel</strong><br />7500 St. Moritz</li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>The Dolder Grand</strong><br />8032 Zürich</li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Suvretta House</strong><br />7500 St. Moritz</li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Altes Postgebäude</strong><br />7000 Chur</li>

      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Höhenklinik Clavadel</strong><br /> 7272 Davos </li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Hotel Eden Roc</strong><br />6612 Ascona</li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Tschuggen Grand Hotel</strong><br />7050 Arosa</li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Waldhaus</strong><br />7018 Flims</li>

      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Kulm Hotel</strong><br />7050 St. Moritz</li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Hotel Fravi</strong><br />7440 Andeer</li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Peaks Place</strong><br />7031 Laax</li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>PRIVA Alpine Lodge</strong><br />7078 Lenzerheide</li>

      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Pflege- und Altersheim Thusis</strong><br />7430 Thusis</li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Altersiedlung Bodmer</strong><br />7000 Chur</li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Oblamatik</strong><br />7000 Chur</li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Business Center</strong><br />7302 Landquart</li>

      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Schulhaus Scola Grava</strong><br />7031 Laax</li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Einkaufszentrum Wiesental</strong><br />7000 Chur</li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Pfarrhaus Evang. Kirchgemeinde Flims</strong><br />7017 Flims Dorf</li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Raiffeisenbank Bündner Rheintal</strong><br />7000 Chur</li>

      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Neubau Regionalsitz GKB</strong><br />7270 Davos Platz</li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Unterwerk Stenna Flims Electric</strong><br />7017 Flims Dorf</li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Alterswohnen SPADA</strong><br />7440 Andeer</li>
      <li className="col-span-1 p-6 text-center bg-gray-100"><strong>Wohnheim Konvikt</strong><br />7000 Chur</li>

      </div>


    </Layout>
  )
}

export default CraftServicesPage

export const query = graphql`{
  refImage1: file(relativePath: {eq: "references/ref-c-1.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  refImage2: file(relativePath: {eq: "references/ref-b-1.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
  refImage3: file(relativePath: {eq: "references/ImageGen.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
  refImage4: file(relativePath: {eq: "references/ref-a-2.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
  refImage5: file(
    relativePath: {eq: "references/carlton-restaurant-da-vittorio-st-moritz.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
}
`